import { useState } from "react";
import axios from "axios";
import { Form, Button, Modal } from "react-bootstrap";
import authHeader from "../../services/auth-header";

const SorareAccess = () => {
  const [sorareEmail, setSorareEmail] = useState([]);
  const [sorarePWD, setSorarePWD] = useState([]);
  const [sorareAud, setSorareAud] = useState([]);
  const [sorarePrivateKey, setSorarePrivateKey] = useState([]);
  const [sorareTOTP, setSorareTOTP] = useState([]);
  const [OTP, setOTP] = useState([]);
  const [showOTP, setShowOTP] = useState(false);
  const [otpChallenge, setOtpChallenge] = useState("");
  const submitSorareAccess = (event) => {
    event.preventDefault();

    axios
      .post(
        "/auth/addSorareConnexion",
        {
          sorareEmail: sorareEmail,
          sorarePWD: sorarePWD,
          sorareAud: sorareAud,
          sorarePrivateKey: sorarePrivateKey,
          sorareTOTP: sorareTOTP,
        },
        {
          headers: authHeader(),
        }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.status === "challenged") {
          setShowOTP(true);
          setOtpChallenge(data?.otpChallenge);
        } else if (data.status === "update") {
          alert("User Updated");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.error === "Wrong credentials") {
          alert("Wrong credentials");
        } else if (error.response?.data?.error === "No credentials") {
          alert("No credentials");
        }
      });
  };

  const validateSorareAccess = () => {
    return (
      sorareEmail.length > 0 && sorareAud.length > 0 && sorarePWD.length > 0
    );
  };

  const handleCloseOTP = () => {
    setShowOTP(false);
  };

  const handleSaveOTP = (event) => {
    event.preventDefault();
    setShowOTP(false);

    axios
      .post(
        "/auth/challengedRefreshOTP/",
        {
          otpChallenge: otpChallenge,
          otpAttempt: OTP,
          sorareAud: sorareAud,
          sorarePrivateKey: sorarePrivateKey,
          sorareTOTP: sorareTOTP,
          sorarePWD: sorarePWD,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        alert("User Updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return [
    <Form onSubmit={submitSorareAccess}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Sorare Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter sorare email"
          onChange={(e) => setSorareEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Choose Sorare Aud</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your Aud"
          onChange={(e) => setSorareAud(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Sorare Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Sorare Password"
          onChange={(e) => setSorarePWD(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Sorare PrivateKey</Form.Label>
        <Form.Control
          type="password"
          placeholder="Sorare PrivateKey"
          onChange={(e) => setSorarePrivateKey(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Sorare TOTP Key</Form.Label>
        <Form.Control
          type="password"
          placeholder="Sorare TOTP Key"
          onChange={(e) => setSorareTOTP(e.target.value)}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!validateSorareAccess()}
      >
        Submit Sorare Access
      </Button>
    </Form>,
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showOTP}
      onHide={handleCloseOTP}
    >
      <Modal.Header closeButton>
        <Modal.Title>OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSaveOTP}>
          <Form.Group>
            <Form.Label> OTP </Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              placeholder="Enter OTP"
              onChange={(e) => setOTP(e.target.value)}
            />

            <Button variant="outline-success" type="submit">
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>,
  ];
};

export default SorareAccess;
